<template>
    <v-container fluid>
		<v-row justify="center" class="ma-0">
			<v-col cols="12" xs="12" class="pa-0 mt-2">
				<v-row class="ma-0">
					<v-select v-if="acces_autorise(['super Admin'])" :style="!affichagePortable ? 'max-width: 300px' : ''" :items="listeCentres" label="Niveau" prepend-inner-icon="mdi-fire-truck" outlined dense hide-details item-text="nom" return-object v-model="centre_selectionne"></v-select>
					<v-checkbox :class="!affichagePortable ? 'mx-12 mt-1' : 'mt-3'" color="primary" v-model="toutes_dates" label="Toutes dates" hide-details dense></v-checkbox>
					<v-menu v-model="openDateDebut" :close-on-content-click="false" :nudge-right="40" transition="scale-transition" offset-y min-width="290px">
						<template v-slot:activator="{ on }">
							<v-text-field outlined dense v-model="date_debut_formatee" readonly :class="!affichagePortable ? 'ml-4' : 'mt-3'" :style="!affichagePortable ? 'max-width: 200px' : ''" label="Date début" prepend-inner-icon="mdi-calendar-month" hide-details :disabled="toutes_dates" v-on="on"></v-text-field>
						</template>
						<v-date-picker v-model="date_debut" :max="date_fin" @input="openDateDebut = false" first-day-of-week="1"></v-date-picker>
					</v-menu>
					<v-menu v-model="openDateFin" :close-on-content-click="false" :nudge-right="40" transition="scale-transition" offset-y min-width="290px">
						<template v-slot:activator="{ on }">
							<v-text-field outlined dense v-model="date_fin_formatee" readonly :class="!affichagePortable ? 'ml-4' : 'mt-3'" :style="!affichagePortable ? 'max-width: 200px' : ''" label="Date fin" prepend-inner-icon="mdi-calendar-month" hide-details :disabled="toutes_dates" v-on="on"></v-text-field>
						</template>
						<v-date-picker v-model="date_fin" :min="date_debut" @input="openDateFin = false" first-day-of-week="1"></v-date-picker>
					</v-menu>
					<v-btn :class="!affichagePortable ? 'ml-12' : 'mt-3 ml-4'" small outlined fab color="primary" :loading="chargement_logs" @click="fetchLogs">
						<v-icon>mdi-magnify</v-icon>
					</v-btn>
				</v-row>
				<v-row class="ma-0">
					<v-text-field outlined dense v-model="texte_recherche" clearable class="mt-12" :style="!affichagePortable ? 'max-width: 400px' : ''" label="Recherche" prepend-inner-icon="mdi-magnify" hide-details></v-text-field>
				</v-row>
				<v-row class="ma-0 mt-2">
					<v-data-table id="table_logs" :items="logs" :headers="headers" disable-pagination :custom-filter="filtrageLigne" :search="texte_recherche" multi-sort hide-default-footer fixed-header :height="'calc(100vh - 300px)'" dense loading-text="Chargement..." :loading="chargement_logs">
						<template v-slot:item.created_at="{ item }">
							{{ date_log_formattee(item.created_at) }}
						</template>
						<template v-slot:item.membre="{ item }">
							{{ item.id_membre != null ? item.membre.user.nom+' '+item.membre.user.prenom : 'Système' }}
						</template>
						<!-- <template v-slot:item.membre_destinataire="{ item }">
							{{ item.id_membre_destinataire != null ? item.membre_destinataire.user.nom+' '+item.membre_destinataire.user.prenom : '' }}
						</template> -->
						<template v-slot:item.detail="{ item }">
							<!-- type "modification des coordonnées" -->
							<v-row v-if="item.id_type == 2" class="ma-0">
								<span class="mr-6" v-if="item.detail.mail != null"><v-icon small class="mr-2">mdi-email-outline</v-icon>{{ item.detail.mail == "" ? 'supprimé' : item.detail.mail }}</span>
								<span v-if="item.detail.tel != null"><v-icon small class="mr-2">mdi-phone</v-icon>{{ item.detail.tel == "" ? 'supprimé' :  item.detail.tel }}</span>
							</v-row>
							<!-- type "paramètres compte" -->
							<v-row v-if="item.id_type == 3" class="ma-0">
								<span class="mr-6" v-if="item.detail.autorisation_mail != null">Autorisation mail: <v-icon :color="item.detail.autorisation_mail ? 'green' :'red'" small class="ml-2">{{ item.detail.autorisation_mail ? 'mdi-check' :'mdi-close' }}</v-icon></span>
								<span class="mr-6" v-if="item.detail.coordonnees_privees != null">Coordonnées privées: <v-icon :color="item.detail.coordonnees_privees ? 'green' :'red'" small class="ml-2">{{ item.detail.coordonnees_privees ? 'mdi-check' :'mdi-close' }}</v-icon></span>
								<span class="mr-6" v-if="item.detail.changement_mdp"><v-icon small class="mr-2">mdi-key</v-icon>Mot de passe changé</span>
								<span class="mr-6" v-if="item.detail.inscription_push != null">Activation notifications: <v-icon :color="item.detail.inscription_push ? 'green' :'red'" small class="ml-2">{{ item.detail.inscription_push ? 'mdi-check' :'mdi-close' }}</v-icon></span>
							</v-row>
							<!-- type " équipes" -->
							<v-row v-if="item.id_type == 4" class="ma-0">
								<span v-if="item.detail.type == 'creation'" class="text-left">Création de l'équipe <span class="font-italic deep-purple--text">{{ item.detail.nom_equipe + ' (' + item.detail.type_equipe + ')'  }}</span> du {{ item.detail.centre }}</span>
								<span v-if="item.detail.type == 'suppression'" class="text-left">Suppression de l'équipe <span class="font-italic deep-purple--text">{{ item.detail.nom_equipe + ' (' + item.detail.type_equipe + ')' }}</span> du {{ item.detail.centre }}</span>
								<span v-if="item.detail.type == 'modification'" class="text-left">Modification des équipes du {{ item.detail.centre }}</span>
							</v-row>
							<!-- type "Compte membre" -->
							<v-row v-if="item.id_type == 5" class="ma-0">
								<span v-if="item.detail.type == 'suppression'" class="text-left">Suppression du compte de <span class="font-italic deep-purple--text">{{ item.detail.membre.prenom + ' ' +item.detail.membre.nom }}</span> du {{ item.detail.centre }}</span>
								<span v-if="item.detail.type == 'creation'" class="text-left">Ajout du compte de <span class="font-italic deep-purple--text">{{ item.detail.membre }}</span> du {{ item.detail.centre }}</span>
								<template v-if="item.detail.type == 'modification'">
									<div class="ma-0 text-left" style="width: 100%">Modification du compte de <span class="font-italic deep-purple--text">{{ item.detail.membre.prenom + ' ' +item.detail.membre.nom }}</span> du {{ item.detail.centre }}:</div>
									<v-row class="ma-0">
										<span class="mr-6" v-if="item.detail.nom"><v-icon small class="mr-2">mdi-account</v-icon>{{ item.detail.nom}}</span>
										<span class="mr-6" v-if="item.detail.grade">Grade: <span class="font-italic font-weight-light ml-2">{{ item.detail.grade.nom }}</span></span>
										<span class="mr-6" v-if="item.detail.mail != null"><v-icon small class="mr-2">mdi-email-outline</v-icon>{{ item.detail.mail == "" ? 'supprimé' : item.detail.mail }}</span>
										<span class="mr-6" v-if="item.detail.tel != null"><v-icon small class="mr-2">mdi-phone</v-icon>{{ item.detail.tel == "" ? 'supprimé' :  item.detail.tel}}</span>
										<span class="mr-6" v-if="item.detail.dispo != null">En dispo: <v-icon :color="item.detail.dispo ? 'green' :'red'" small class="ml-2">{{ item.detail.dispo ? 'mdi-check' :'mdi-close' }}</v-icon></span>
										<span class="mr-6" v-if="item.detail.statut != null">Statut: <v-chip label x-small :color="getStatut(item.detail.statut).couleur" class="ml-2">{{getStatut(item.detail.statut).nom}}</v-chip></span>
										<span class="mr-6" v-if="item.detail.photo"><v-icon small class="mr-2">mdi-camera</v-icon>Photo</span>
										<span class="mr-6" v-if="item.detail.equipe">Equipe: <span class="font-italic font-weight-light ml-2">{{ item.detail.equipe ? item.detail.equipe.nom : 'aucune' }}</span></span>
									</v-row>
								</template>
								<span v-if="item.detail.type == 'verrouillage'" class="text-left"><v-icon small class="mr-2">{{ item.detail.verrou ? 'mdi-lock-open-variant' : 'mdi-lock' }}</v-icon>{{ item.detail.verrou ? 'Déverrouillage ' :'Verrouillage ' }} du compte de <span class="font-italic deep-purple--text">{{ item.detail.membre.prenom + ' ' +item.detail.membre.nom }}</span> du {{ item.detail.centre }}</span>
								<span v-if="item.detail.type == 'reinit_mdp'" class="text-left"><v-icon small class="mr-2">mdi-key</v-icon>Réinitialisation du mot de passe du compte de <span class="font-italic deep-purple--text">{{ item.detail.membre.prenom + ' ' +item.detail.membre.nom }}</span> du {{ item.detail.centre }}</span>
								<template v-if="item.detail.type == 'modif_aptitudes'">
									<div class="ma-0 text-left" style="width: 100%">Modification des aptitudes de <span class="font-italic deep-purple--text">{{ item.detail.membre.prenom + ' ' +item.detail.membre.nom }}</span>:</div>
									<span v-if="item.detail.aptitudes.length == 0" class="font-italic font-weight-light">Aucune</span>
									<v-chip v-else v-for="aptitude in item.detail.aptitudes" :key="aptitude.id" x-small class="mr-2 mb-1">{{ aptitude.nom }}</v-chip>
								</template>
								<template v-if="item.detail.type == 'modif_roles'">
									<div class="ma-0 text-left" style="width: 100%">Modification des rôles et permission de <span class="font-italic deep-purple--text">{{ item.detail.membre.prenom + ' ' +item.detail.membre.nom }}</span> du {{ item.detail.centre }}:</div>
									<div class="text-left" style="width: 100%">
										<span class="mr-2">Rôles:</span>
										<span v-if="item.detail.roles.length == 0" class="font-italic font-weight-light">Aucun</span>
										<v-chip v-else v-for="role in item.detail.roles" :key="role.id" x-small class="mr-2 mb-1">{{ role.name }}</v-chip>
									</div>
									<span class="mr-2">Permissions:</span>
									<span v-if="item.detail.permissions.length == 0" class="font-italic font-weight-light">Aucune</span>
									<v-chip v-else v-for="permission in item.detail.permissions" :key="permission.id" x-small class="mr-2 mb-1">{{ permission.name }}</v-chip>
								</template>
							</v-row>
							<!-- type "planning centre" -->
							<v-row v-if="item.id_type == 6" class="ma-0">
								<span v-if="item.detail.type == 'verrouillage'" class="text-left"><v-icon small class="mr-2">{{ item.detail.verrou == 1 ? 'mdi-lock-open-variant' : 'mdi-lock' }}</v-icon>{{ item.detail.verrou == 1 ? 'Déverrouillage ' :'Verrouillage ' }} du planning de <span class="font-italic deep-purple--text">{{ formattageDatePlanning(item.detail.mois) }}</span></span>
								<span v-if="item.detail.type == 'sauvegarde'" class="text-left"><v-icon small class="mr-2">mdi-content-save</v-icon>Sauvegarde du planning de <span class="font-italic deep-purple--text">{{ formattageDatePlanning(item.detail.mois) }}</span></span>
								<span v-if="item.detail.type == 'diffusion'" class="text-left"><v-icon small class="mr-2">mdi-cloud</v-icon>Diffusion du planning de <span class="font-italic deep-purple--text">{{ formattageDatePlanning(item.detail.mois) }}</span></span>
								<span v-if="item.detail.type == 'date_limite'" class="text-left"><v-icon small class="mr-2">mdi-calendar</v-icon>Modification de la date limite du planning de <span class="font-italic deep-purple--text">{{ formattageDatePlanning(item.detail.mois) }}</span> au <span class="font-italic deep-purple--text">{{ formattageDateLimite(item.detail.date) }}</span></span>
								<span v-if="item.detail.type == 'attribution_garde'" class="text-left"><v-icon small class="mr-2">mdi-plus-circle</v-icon>Attribution d'une garde à <span class="font-italic deep-purple--text">{{ item.detail.destinataire }}</span> le {{ formattageDate(item.detail.garde.date) }} de {{ item.detail.garde.periode.nom }}</span>
								<span v-if="item.detail.type == 'annulation_garde'" class="text-left"><v-icon small class="mr-2">mdi-minus-circle</v-icon>Annulation de la garde de <span class="font-italic deep-purple--text">{{ item.detail.destinataire }}</span> le {{ formattageDate(item.detail.garde.date) }} de {{ item.detail.garde.periode.nom }}</span>
							</v-row>
							<!-- type "planning individuel" -->
							<v-row v-if="item.id_type == 7" class="ma-0">
								<template v-if="item.detail.type == 'sauvegarde_dispos'">
									<div class="text-left" style="width: 100%"><v-icon small class="mr-2">mdi-content-save</v-icon>Sauvegarde des dispos de <span class="font-italic deep-purple--text">{{ formattageDatePlanning(item.detail.mois) }}</span>:</div>
									<span v-if="item.detail.dispos.length == 0" class="font-italic font-weight-light">Aucune</span>
									<v-chip v-else x-small v-for="dispo in item.detail.dispos" :key="dispo.id" class="mr-2 mb-1">{{ formattageDateDispo(dispo) }}</v-chip>
								</template>
							</v-row>	
							<!-- type "astreintes/manoeuvres" -->
							<v-row v-if="item.id_type == 8" class="ma-0">
								<template v-if="item.detail.type == 'creation'">
									<div class="text-left" style="width: 100%">
										<v-icon small class="mr-2">mdi-plus-circle</v-icon>
										<span v-if="item.detail.genre == 'astreinte'">Ajout d'une astreinte pour l'équipe <span class="font-italic deep-purple--text">{{ item.detail.equipe ? item.detail.equipe.nom : '' }}</span>: du <span class="font-italic deep-purple--text">{{ formattageDateLimite(item.detail.date_debut) }}</span> au <span class="font-italic deep-purple--text">{{ formattageDateLimite(item.detail.date_fin) }}</span></span>
										<span v-else-if="item.detail.genre == 'manoeuvre'">Ajout d'une manoeuvre pour <span class="font-italic deep-purple--text">{{ getListeEquipes(item.detail) }}</span>: le <span class="font-italic deep-purple--text">{{ formattageDateManoeuvre(item.detail.date_debut, item.detail.date_fin) }}</span>
											comptant pour la manoeuvre de <span class="font-italic deep-purple--text">{{ formattageMoisManoeuvre(item.detail.mois, item.detail.annee) }}</span>
										</span>
									</div>
								</template>
								<template v-if="item.detail.type == 'suppression'">
									<div class="text-left" style="width: 100%">
										<v-icon small class="mr-2">mdi-delete</v-icon>
										<span v-if="item.detail.genre == 'astreinte'">Suppression de l'astreinte de l'équipe <span class="font-italic deep-purple--text">{{ item.detail.element.equipe.nom }}</span>: du <span class="font-italic deep-purple--text">{{ formattageDateLimite(item.detail.element.date_debut) }}</span> au <span class="font-italic deep-purple--text">{{ formattageDateLimite(item.detail.element.date_fin) }}</span></span> 
										<span v-else-if="item.detail.genre == 'manoeuvre'">Suppression d'une manoeuvre pour <span class="font-italic deep-purple--text">{{ getListeEquipes(item.detail.element) }}</span>: le <span class="font-italic deep-purple--text">{{ formattageDateManoeuvre(item.detail.element.date_debut, item.detail.element.date_fin) }}</span>
											comptant pour la manoeuvre de <span class="font-italic deep-purple--text">{{ formattageMoisManoeuvre(item.detail.element.mois, item.detail.element.annee) }}</span>
										</span>
									</div>
								</template>
								<template v-if="item.detail.type == 'modification'">
									<div class="ma-0 text-left" style="width: 100%">Modification d'une {{ item.detail.genre == 'astreinte' ? 'astreinte': 'manoeuvre'}}:</div>
									<div class="text-left" style="width: 100%">
										<span v-if="item.detail.genre == 'astreinte'">
											<span class="font-italic deep-purple--text">{{ item.detail.ancien_element.equipe.nom }}</span>: du <span class="font-italic deep-purple--text">{{ formattageDateLimite(item.detail.ancien_element.date_debut) }}</span> au <span class="font-italic deep-purple--text">{{ formattageDateLimite(item.detail.ancien_element.date_fin) }}</span>
											<v-icon small class="mx-2">mdi-arrow-right-thick</v-icon>
											<span class="font-italic deep-purple--text">{{ item.detail.nouvel_element.equipe.nom }}</span>: du <span class="font-italic deep-purple--text">{{ formattageDateLimite(item.detail.nouvel_element.date_debut) }}</span> au <span class="font-italic deep-purple--text">{{ formattageDateLimite(item.detail.nouvel_element.date_fin) }}</span>
										</span> 
										<span v-else-if="item.detail.genre == 'manoeuvre'">
											<span class="font-italic deep-purple--text">{{ getListeEquipes(item.detail.ancien_element) }}</span>: le <span class="font-italic deep-purple--text">{{ formattageDateManoeuvre(item.detail.ancien_element.date_debut, item.detail.ancien_element.date_fin) }}</span>
											comptant pour la manoeuvre de <span class="font-italic deep-purple--text">{{ formattageMoisManoeuvre(item.detail.ancien_element.mois, item.detail.ancien_element.annee) }}</span>
											<v-icon small class="mx-2">mdi-arrow-right-thick</v-icon>
											<span class="font-italic deep-purple--text">{{ getListeEquipes(item.detail.nouvel_element) }}</span>: le <span class="font-italic deep-purple--text">{{ formattageDateManoeuvre(item.detail.nouvel_element.date_debut, item.detail.nouvel_element.date_fin) }}</span>
											comptant pour la manoeuvre de <span class="font-italic deep-purple--text">{{ formattageMoisManoeuvre(item.detail.nouvel_element.mois, item.detail.nouvel_element.annee) }}</span>
										</span>
									</div>
								</template>
								<template v-if="item.detail.type == 'confirmation_participation'">
									<div class="text-left" style="width: 100%">Confirmation de participation à la manoeuvre de l'équipe 
										<span class="font-italic deep-purple--text">{{ item.detail.manoeuvre.equipe.nom }}</span>: le <span class="font-italic deep-purple--text">{{ formattageDateManoeuvre(item.detail.manoeuvre.date_debut, item.detail.manoeuvre.date_fin) }}</span>
									</div>
								</template>
							</v-row>
							<!-- type "FMPA" -->
							<v-row v-if="item.id_type == 9" class="ma-0">
								<template v-if="item.detail.type == 'suppression'">
									<div class="text-left" style="width: 100%">
										<v-icon small class="mr-2">mdi-delete</v-icon>
										<span>Suppression de la FMPA <span class="font-italic deep-purple--text">{{ item.detail.fmpa.annee }}</span> du <span class="font-italic deep-purple--text">{{ item.detail.centre.nom }}</span></span> 
									</div>
								</template>
								<template v-if="item.detail.type == 'creation'">
									<div class="text-left" style="width: 100%">
										<v-icon small class="mr-2">mdi-plus-circle</v-icon>
										<span>Création de la FMPA <span class="font-italic deep-purple--text">{{ item.detail.annee }}</span> du <span class="font-italic deep-purple--text">{{ item.detail.centre.nom }}</span></span> 
									</div>
								</template>
								<template v-if="item.detail.type == 'modification'">
									<div class="text-left" style="width: 100%">
										<v-icon small class="mr-2">mdi-autorenew</v-icon>
										<span>Modification de la FMPA <span class="font-italic deep-purple--text">{{ item.detail.fmpa.annee }}</span> du <span class="font-italic deep-purple--text">{{ item.detail.centre.nom }}</span></span> 
									</div>
								</template>
								<template v-if="item.detail.type == 'suppression_individuelle'">
									<div class="text-left" style="width: 100%">
										<div><v-icon small class="mr-2">mdi-delete</v-icon>Suppression de la ligne FMPA <span class="font-italic deep-purple--text">{{ item.detail.realisation.theme.fmpa.annee }}</span> du <span class="font-italic deep-purple--text">{{ item.detail.centre.nom }}</span> pour <span class="font-italic deep-purple--text">{{ item.detail.realisation.membre_centre.user.prenom+' '+item.detail.realisation.membre_centre.user.nom }}:</span> </div>
										<span class="font-weight-bold mr-1">{{item.detail.realisation.theme.type.sigle}}</span><span>{{item.detail.realisation.theme.titre+' / '+item.detail.realisation.theme.description+': '}}</span>
										<span v-if="item.detail.realisation.theme.id_type != 4" class="font-weight-bold deep-purple--text">{{ formattageDuree(item.detail.realisation.duree_effectuee) }}</span>
										<span v-else class="font-weight-bold deep-purple--text">{{ item.detail.realisation.est_apte ? 'APTE' : 'INAPTE'}}</span>
										 le <span>{{formattageDate(item.detail.realisation.date)}}</span> 
									</div>
								</template>
								<template v-if="item.detail.type == 'modification_individuelle'">
									<div class="text-left" style="width: 100%">
										<div><v-icon small class="mr-2">mdi-autorenew</v-icon>Modification de la ligne FMPA <span class="font-italic deep-purple--text">{{ item.detail.ancienne_realisation.theme.fmpa.annee }}</span> du <span class="font-italic deep-purple--text">{{ item.detail.centre.nom }}</span> pour <span class="font-italic deep-purple--text">{{ item.detail.ancienne_realisation.membre_centre.user.prenom+' '+item.detail.ancienne_realisation.membre_centre.user.nom }}:</span> </div>
										<span class="font-weight-bold mr-1">{{item.detail.ancienne_realisation.theme.type.sigle}}</span><span>{{item.detail.ancienne_realisation.theme.titre+' / '+item.detail.ancienne_realisation.theme.description+': '}}</span>
										<span v-if="item.detail.ancienne_realisation.theme.id_type != 4" class="font-weight-bold deep-purple--text">{{ formattageDuree(item.detail.ancienne_realisation.duree_effectuee) }}</span>
										<span v-else class="font-weight-bold deep-purple--text">{{ item.detail.ancienne_realisation.est_apte ? 'APTE' : 'INAPTE' }}</span>
										 le <span>{{formattageDate(item.detail.ancienne_realisation.date)}}</span>  
										
										<v-icon class="mx-1" size="20">mdi-arrow-right-thick</v-icon>
										
										<span class="font-weight-bold mr-1">{{item.detail.nouvelle_realisation.theme.type.sigle}}</span><span>{{item.detail.nouvelle_realisation.theme.titre+' / '+item.detail.nouvelle_realisation.theme.description+': '}}</span>
										<span v-if="item.detail.nouvelle_realisation.theme.id_type != 4" class="font-weight-bold deep-purple--text">{{ formattageDuree(item.detail.nouvelle_realisation.duree_effectuee) }}</span>
										<span v-else class="font-weight-bold deep-purple--text">{{ item.detail.nouvelle_realisation.est_apte ? 'APTE' : 'INAPTE' }}</span>
										 le <span>{{formattageDate(item.detail.nouvelle_realisation.date)}}</span>
									</div>
								</template>
								<template v-if="item.detail.type == 'creation_individuelle'">
									<div class="text-left" style="width: 100%">
										<div><v-icon small class="mr-2">mdi-plus-circle</v-icon>Ajout de la ligne FMPA <span class="font-italic deep-purple--text">{{ item.detail.nouvelle_realisation.theme.fmpa.annee }}</span> du <span class="font-italic deep-purple--text">{{ item.detail.centre.nom }}</span> pour <span class="font-italic deep-purple--text">{{ item.detail.nouvelle_realisation.membre_centre.user.prenom+' '+item.detail.nouvelle_realisation.membre_centre.user.nom }}:</span> </div>
										<span class="font-weight-bold mr-1">{{item.detail.nouvelle_realisation.theme.type.sigle}}</span><span>{{item.detail.nouvelle_realisation.theme.titre+' / '+item.detail.nouvelle_realisation.theme.description+': '}}</span>
										<span v-if="item.detail.nouvelle_realisation.theme.id_type != 4" class="font-weight-bold deep-purple--text">{{ formattageDuree(item.detail.nouvelle_realisation.duree_effectuee) }}</span>
										<span v-else class="font-weight-bold deep-purple--text">{{ item.detail.nouvelle_realisation.est_apte ? 'APTE' : 'INAPTE' }}</span>
										 le <span>{{formattageDate(item.detail.nouvelle_realisation.date)}}</span>
										<span v-if="item.detail.nouvelle_realisation.commentaire" class="ml-1">({{item.detail.nouvelle_realisation.commentaire}})</span>
									</div>
								</template>
								<template v-if="item.detail.type == 'creation_multiple'">
									<div class="text-left" style="width: 100%">
										<div>
											<v-icon small class="mr-2">mdi-plus-circle</v-icon>
											Ajout de lignes FMPA 
											<span class="font-italic deep-purple--text">{{ item.detail.fmpa.annee }}</span> 
											du <span class="font-italic deep-purple--text">{{ item.detail.centre.nom }}</span> pour 
											<v-chip x-small label v-for="spv in item.detail.liste_membres_concernes" :key="spv.id" class="mr-1 mb-1">{{ spv.user.prenom+' '+spv.user.nom }}</v-chip> 
											<span>:</span>
										</div>
										<div v-for="realisation in item.detail.nouvelle_realisations" :key="realisation.id">
											<span class="font-weight-bold mr-1">{{realisation.theme.categorie}}</span>
											<span>{{realisation.theme.titre+' / '+realisation.theme.description+': '}}</span>
											<span v-if="realisation.theme.id_type != 4" class="font-weight-bold deep-purple--text">{{ formattageDuree(realisation.duree) }}</span>
											<span v-else class="font-weight-bold deep-purple--text">{{ realisation.est_apte ? 'APTE' : 'INAPTE' }}</span>
											le <span>{{formattageDate(realisation.date)}}</span>
											<span v-if="realisation.commentaire" class="ml-1">({{realisation.commentaire}})</span>
										</div>
									</div>
								</template>
								<template v-if="item.detail.type == 'validation_hors_manoeuvre'">
									<div class="text-left" style="width: 100%">
										<div><v-icon small class="mr-2">mdi-autorenew</v-icon>Modification de la ligne FMPA <span class="font-italic deep-purple--text">{{ item.detail.ancienne_realisation.theme.fmpa.annee }}</span> du <span class="font-italic deep-purple--text">{{ item.detail.centre.nom }}</span> pour <span class="font-italic deep-purple--text">{{ item.detail.ancienne_realisation.membre_centre.user.prenom+' '+item.detail.ancienne_realisation.membre_centre.user.nom }}:</span> </div>
										<span class="font-weight-bold mr-1">{{item.detail.ancienne_realisation.theme.type.sigle}}</span><span>{{item.detail.ancienne_realisation.theme.titre+' / '+item.detail.ancienne_realisation.theme.description+': '}}</span>
										<span v-if="item.detail.ancienne_realisation.theme.id_type != 4" class="font-weight-bold deep-purple--text">{{ formattageDuree(item.detail.ancienne_realisation.duree_effectuee) }}</span>
										<span v-else class="font-weight-bold deep-purple--text">{{ item.detail.ancienne_realisation.est_apte ? 'APTE' : 'INAPTE' }}</span>
										 le <span>{{formattageDate(item.detail.ancienne_realisation.date)}}</span>  
										
										<v-icon class="mx-1" size="20">mdi-arrow-right-thick</v-icon>
										<span>hors manoeuvre: </span>
										<span class="font-weight-bold deep-purple--text">{{ item.detail.nouvelle_realisation.hors_manoeuvre_compte ? 'pris en compte' : 'non pris en compte' }}</span>
									</div>
								</template>
							</v-row>
						</template>
					</v-data-table>
				</v-row>
			</v-col>
		</v-row>
	</v-container>
</template>

<script>
	import axios from 'axios'
	import Moment from 'moment'
	Moment.locale('fr')
	import { extendMoment } from 'moment-range'
	const moment = extendMoment(Moment)
    export default {
        created() {
			this.$store.commit('majTitrePage', 'Journal')
        },
        mounted() {
			//	initialisation du centre
			this.$store.dispatch('getListeCentres').then( () => {
				this.centre_selectionne = this.listeCentres.find( centre => centre.id ==  this.$store.getters.membreCourant.centre.id)
				this.date_fin = moment().format('YYYY-MM-DD')
				this.date_debut = moment().subtract(6, 'months').format('YYYY-MM-DD')
			})
			.then( () => {
				this.fetchLogs()
			})
			
        },
        data() {
			return {
				centre_selectionne: null,
				chargement_logs: false,
				date_debut: null,
				date_fin: null,
				headers: [
					{
						text: 'Date',
						align: 'center',
						sortable: true,
						value: 'created_at',
						width: '180',
					},
					{
						text: 'Origine',
						align: 'center',
						sortable: true,
						value: 'membre',
						width: '200'
					},
					// {
					// 	text: 'Destinataire',
					// 	align: 'center',
					// 	sortable: true,
					// 	value: 'membre_destinataire',
					// 	width: '200'
					// },
					{
						text: 'Action',
						align: 'center',
						sortable: true,
						value: 'type.nom',
						width: '300'
					},
					{
						text: 'Détail',
						align: 'center',
						sortable: true,
						value: 'detail',
						width: '*'
					},
				],
				logs: [],
				openDateDebut: false,
				openDateFin: false,
				texte_recherche: '',
				toutes_dates: false            
			}
		},
		computed: {
			affichagePortable() {
				return this.$vuetify.breakpoint.name == 'xs'
			},
			centre_courant() {
				return this.$store.getters.membreCourant.centre
			},
			date_debut_formatee() {
				return this.formattageDate(this.date_debut)
			},
			date_fin_formatee() {
				return this.formattageDate(this.date_fin)
			},
			listeCentres() {
				let liste = this.$store.getters.liste_centres
				liste.push(
					{
						id: 0,
						nom: 'Tous les centres'
					},
					{
						id: 'sys',
						nom: 'Système'
					}
				)
				return liste
			},
  
		},
		methods: {
			acces_autorise(permissions_requises) {
                // retourne true si l'utilisateur à les permissions listées
                
                let permission_trouvee = false

                if(permissions_requises.length > 0 && this.$store.getters.membreCourant) {
                
                    const roles_utilisateur = this.$store.getters.membreCourant.roles
                    const permissions_utilisateur = this.$store.getters.membreCourant.permissions

                    const estSuperAdmin = roles_utilisateur.find(role => role.name == 'Super admin')
                    

                    //	si super admin on autorise tout
                    if (estSuperAdmin) {
                        permission_trouvee = true
                    }
                    else {
                        //	on cherche si la permission demandée se trouve dans un des roles de l'utilisateur
                        roles_utilisateur.forEach(role => {
                            role.permissions.forEach(permission => {
                                    if( permissions_requises.includes(permission.name) ) {
                                        permission_trouvee = true
                                    }
                                })
                        })
                    

                        // si la permission n'a pas déjà été trouvée, on cherche si elle est dans les permissions directes
                        if (!permission_trouvee) {
                            
                            permissions_utilisateur.forEach(permission => {
                                if( permissions_requises.includes(permission.name) ) {
                                    permission_trouvee = true
                                }
                            })
                        }
                    }
                }

                //	on autorise si pas de permission ou si une permission et que la permission est trouvée
                return permissions_requises.length == 0 || (permissions_requises.length > 0 && permission_trouvee)
			},
			date_log_formattee(date) {
				if(!date) return ''
				return moment(date).format('DD/MM/YYYY HH:mm:ss')
			},
			fetchLogs() {
				this.chargement_logs = true
				axios.post('api/centre/logs', { id_membre:this.$store.getters.membreCourant.id, id_centre: this.centre_selectionne ? this.centre_selectionne.id : null ,date_debut: this.date_debut, date_fin: this.date_fin, toutes_dates: this.toutes_dates })
                    .then( (reponse) => {
						this.logs = reponse.data.logs
                    })
                    .catch( (error) => {
						if(error.response.data.error) {
							this.snackbar('error', error.response.data.error)
						}
						else {
							this.snackbar('error', 'Erreur lors du chargement du journal')
						}
                    })
                    .then(() => {
						this.chargement_logs = false
                    })
			},
			filtrageLigne(value, search, item) {
				return value != null &&
					this.texte_recherche != null && (
						item.membre && item.membre.user.nom.toString().toUpperCase().indexOf(this.texte_recherche.toUpperCase()) !== -1 ||
						item.membre && item.membre.user.prenom.toString().toUpperCase().indexOf(this.texte_recherche.toUpperCase()) !== -1 ||
						//item.membre_destinataire && item.membre_destinataire.user.nom.toString().toUpperCase().indexOf(this.texte_recherche.toUpperCase()) !== -1 ||
						//item.membre_destinataire && item.membre_destinataire.user.prenom.toString().toUpperCase().indexOf(this.texte_recherche.toUpperCase()) !== -1 ||
						item.created_at.toString().toUpperCase().indexOf(this.texte_recherche.toUpperCase()) !== -1 ||
						item.type.nom.toString().toUpperCase().indexOf(this.texte_recherche.toUpperCase()) !== -1 ||

						//item.detail && item.detail.tel && item.detail.tel.toString().toUpperCase().indexOf(this.texte_recherche.toUpperCase()) !== -1 ||
						//item.detail && item.detail.mail && item.detail.mail.toString().toUpperCase().indexOf(this.texte_recherche.toUpperCase()) !== -1 ||
						
						item.detail && JSON.stringify(item.detail).toUpperCase().indexOf(this.texte_recherche.toUpperCase()) !== -1 


					)
					
			},
			formattageDate(date) {
				if (!date) return null
				return moment(date).format('DD/MM/YYYY')
			},
			formattageDateDispo(dispo) {
				return moment(dispo.date).format('DD/MM/YYYY')+' ('+dispo.periode.nom+')'
			},
			formattageDateLimite(date) {
				return moment(date).format('dddd DD/MM/YYYY HH:mm')
			},
			formattageDateManoeuvre(date_debut, date_fin) {
				return moment(date_debut).format('dddd DD/MM/YYYY')+' de '+moment(date_debut).format('HH:mm')+' à '+moment(date_fin).format('HH:mm')
			},
			formattageDatePlanning(date) {
				return moment(date).format('MMMM YYYY')
			},
			formattageDuree(duree) {
				if(!duree) return ''
				return moment(duree, 'HH:mm:ss').format('HH:mm')
			},
			formattageMoisManoeuvre(mois, annee) {
				return moment(mois, 'MM').format('MMMM')+' '+annee
			},
			getListeEquipes(manoeuvre) {
				let texte = ''
				for (const [index, equipe] of manoeuvre.equipes.entries()) {
					if(index != manoeuvre.equipes.length -1) {
						texte += equipe.nom+' / '
					}
					else {
						texte += equipe.nom
					} 
				}
				return texte
			},
			getPhoto(chemin){
				return "/storage/"+chemin;
			},
			getStatut(statut) {
                // renvoie le nom dustatut et la couleur à utiliser
                // 0: SPV
                // 1: SPP
                // 2: PATS
                let nom = ''
                let couleur = ''

                switch (statut) {
                    case 0:
                        nom = 'SPV'
                        couleur = 'light-green lighten-4'
                        break;
                    case 1:
                        nom = 'SPP'
                        couleur = 'blue lighten-4'
                        break;
                    case 2:
                        nom = 'PATS'
                        couleur = 'lime lighten-4'
                        break;
                    default:
                        break;
                }

                return {
                    nom: nom,
                    couleur: couleur
                }
            },
			premiereLettreMaj(text){
				return text.charAt(0).toUpperCase();
			},
			snackbar(type,message){
                this.$store.commit('afficheSnackbar', {type: type, message: message});
			}

        },
        validations: {
            
		},
		watch: {
			centre_courant() {
				
				//  si pas autorisé à rester sur cette page on le vire
                if(!this.acces_autorise('acces journal')) {
					this.$router.push('/')
				}
				else {
					this.$store.dispatch('getListeCentres').then( () => {
						this.centre_selectionne = this.listeCentres.find( centre => centre.id ==  this.$store.getters.membreCourant.centre.id)
						this.date_fin = moment().format('YYYY-MM-DD')
						this.date_debut = moment().subtract(6, 'months').format('YYYY-MM-DD')
					})
					.then( () => {
						this.fetchLogs()
					})
				}
			},
			centre_selectionne() {
				this.fetchLogs()
				if(this.centre_selectionne.id == 0) {
					this.$store.commit('majTitrePage', 'Journal de tous les centres')
				}
				else if(this.centre_selectionne.id == 'sys') {
					this.$store.commit('majTitrePage', 'Journal système')
				}
				else {
					this.$store.commit('majTitrePage', 'Journal du '+this.centre_selectionne.nom)
				}
				
			}
		}
  	}
</script>

<style>

.liste_annuaire {
	overflow-y: auto;
	max-height: calc(100vh - 115px);
	/* min-height: calc(100vh - 115px); */
}
.header_annuaire {
	padding-left: 0px !important;
	padding-top: 0px !important;
	padding-bottom: 0px !important;
	
}

.panel_annuaire  {
	border-style: solid;
	border-width: 1px;
	border-color: rgb(224, 224, 224);
	
}

.v-expansion-panel:before {
  box-shadow: none !important;
  
}

.v-expansion-panel {
	border-top: 0;
}

#table_logs {
	width: 100%;
}

#table_logs .v-data-table-header th {
	background-color: #F0F4C3 !important;
}

#table_logs .v-data-table__wrapper {
	background-color: #FAFAFA !important;
}


</style>